<template>
    <!-- 
        Модальне вікно створення папки для холодних дзвінків
    -->

    <modal>
        <template v-slot:title>{{ $t('Folder') }} {{ form.folderName }}</template>
        <template v-slot:head-bottom>
            <button type="button" class="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email"  @click="$emit('close')"><i class="ri-close-fill align-bottom"></i></button>
        </template>
        <template v-slot:body>
            <!-- Назва папки -->
            <div class="mb-3">
                <h4 class="fs-15">{{ $t('Point_name') }}</h4>
                <input type="text" class="form-control" v-model="form.folderName">
            </div>
            <div class="mb-3">
                <h4 class="fs-15">{{ $t('desc') }}</h4>
                <input type="text" class="form-control" v-model="form.description">
            </div>
        </template>
        <template v-slot:footer-bottom>

            <!-- Кнопка "Додати" -->
            <button 
                v-if="perm != true"
                type="button" 
                class="btn btn-success" 
                @click="add"
            >
                {{ $t('Add') }}
            </button>

            <!-- Кнопка "Змінити" -->
            <button 
                v-else 
                type="button" 
                class="btn btn-warning" 
                @click="edit"
            >
                {{ $t('Edit') }}
            </button>
        </template>
    </modal>
</template>
 
<script>
import modal from '@/components/modal/dialog' // компонент модального вікна
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import { coldCallFolders } from '@/API.js'; // клас для взаємодії з API 
import { storeS } from '@/store';  // глобальне сховище даних
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм
 
let apiServe = new coldCallFolders();
 
export default ({
    props: ['perm', 'obj'],
    components: {
        modal,
        Multiselect
    },
    data(){
        return {
            form: {
                folderName: "", // назва папки
                description: "" // опис папки
            },
        }
    },
    
    created(){

        // Перевіряємо стан режим редагування
        if(this.perm == true) {
            this.form.folderName = this.obj.name
            this.form.description = this.obj.description
        }
    },
    methods: {
        add() {
            /**
                * Додавання нової папки з урахуванням валідації полів.
            */
            
            if (!this.form.folderName) {
                //перевіряємо чи вказана назва
                this.$toast.error(this.$t('enterNameFolder'));
                return
            }

            apiServe.createFolder(this.form).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('сreatedT'));
                    this.$emit('close')
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var errorText = 'Помилка створення папки в холодних дзвінках'; 
                    var statusRequest = result.status;
                    var api = 'createFolder';
                    var fileRequest = 'src/views/calls/cold/folders/new.vue';
                    var params = this.form;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        edit(){
            /**
                * Редагування папки.
            */

            var tempForm = {
                name: this.form.folderName,
                description: this.form.description
            }

            apiServe.rename(this.obj.callFolderId, tempForm).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('alertChanged'));
                    this.$emit('close')
                    this.$emit('getdata')
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var tempObj = {
                        "callFolderId": this.obj.callFolderId,
                        "params": tempForm
                    }

                    var errorText = 'Помилка створення штрафу'; 
                    var statusRequest = result.status;
                    var api = 'rename';
                    var fileRequest = 'src/views/calls/cold/folders/new.vue';
                    var params = tempObj;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        }
    },
    mounted(){
    },
    computed: {
        
    }
 })
 </script>
 