<template>
    <!-- 
        Сторінка з папки по холодним дзвінкам
    -->

    <!-- Компонент хедеру розділу -->
    <PageHeader :title="title" />

    <div class="row g-4 mb-3">
        <!-- Кнопка додати -->
        <div class="col-sm-auto">
            <div>
                <a 
                    @click="showNewModal = true" 
                    class="btn btn-success"
                >
                    <i class="ri-add-line align-bottom me-1"></i> 
                    {{ $t('Add') }}
                </a>
            </div>
        </div>

        <!-- Кнопка імпорту дзвінків -->
        <div class="col-sm-auto">
            <div>
                <a 
                    @click="showImportForm = true" 
                    class="btn btn-success"
                >
                    <i class="bx bx-import align-bottom me-1 mb-1"></i> 
                    {{ $t('import') }}
                </a>
            </div>
        </div>

        <!-- Пошук -->
        <div class="col-sm">
            <div class="d-flex justify-content-sm-end gap-2">
                <div class="search-box ms-2">
                    <input type="text" class="form-control" v-model="this.objParams.search" :placeholder="$t('searc_inp')" @input="searchB(this.objParams.search)"/>
                    <i class="ri-search-line search-icon"></i>
                </div>
            </div>
        </div>
    </div>

    <!-- Блок з пагінацією та вибору ліміту -->
    <div class="row g-0 text-center text-sm-start align-items-center mb-4" >
        <!-- Всього папок -->
        <div class="col-sm-6" >
            <div>
                <p class="mb-sm-0 text-muted">
                    {{ $t('Total') }} 
                    <span class="fw-semibold">
                        {{ objPages.show }}
                    </span> 
                    {{ $t('outOf') }}
                    <span class="fw-semibold text-decoration-underline">
                        {{ objPages.total }}
                    </span>
                </p>
            </div>
        </div>
        
        <!-- Вибір ліміту -->
        <div class="col-sm-6" >
            <ul class="pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0">
                <div class="pagination-wrap hstack gap-2" style="margin-right:5px;" >
                    <select class="form-select mb-0" v-model="limit" @change="changelimit(limit)">
                        <option v-for="item in limits" :key="item" :value="item.value" >{{item.value}}</option>
                    </select>
                </div>
                <li v-for="(pageNumber, index) in objPages.links" :key="index" :class="`page-item copy ${pageNumber.current == 'true' ? 'active' : null}`">
                    <a class="page-link" @click="getdata(pageNumber.page)">{{ pageNumber.page }}</a>
                </li>
            </ul>
        </div>
    </div>

    <!-- Список папок -->
    <b-row>
        <div
            class="col-xxl-3 col-sm-6 project-card"
            v-for="(item, index) of rows"
            :key="index"
        >
            <div class="card" >
                <div class="card-body">
                    <div :class="`p-3 mt-n3 mx-n3 badge-soft-info rounded-top`">
                        <div class="d-flex align-items-center">
                            <div class="flex-grow-1">
                                <!-- Відображаємо назву папки -->
                                <h5 class="mb-0 fs-14 click" 
                                    @click="$router.push(
                                        { 
                                            name: 'pages-list-open-coldCall', 
                                            params: { 
                                                id: item.callFolderId, 
                                                title: item.name
                                            } 
                                        }
                                    )"
                                >
                                    <b>{{ item.name }}</b> <span class="badge badge-soft-secondary">{{ item.countRequests }}</span>
                                </h5>
                            </div>

                            <!-- Дропдаун меню -->
                            <div class="flex-shrink-0">
                                <div class="d-flex gap-1 align-items-center my-n2">
                                    
                                    <div class="dropdown">
                                        <button
                                            class="btn btn-link text-muted p-1 mt-n2 py-0 text-decoration-none fs-15 shadow-none"
                                            data-bs-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="true"
                                        >
                                            <MoreHorizontalIcon class="icon-sm"></MoreHorizontalIcon>
                                        </button>

                                        <div class="dropdown-menu dropdown-menu-end">
                                            <!-- Редагувати папку -->
                                            <button
                                                class="dropdown-item"
                                                @click="editModal(item)"
                                            >
                                                <i class="ri-pencil-fill align-bottom me-2 text-muted"></i>
                                                {{ $t('Edit') }}
                                            </button>
                                            <div class="dropdown-divider"></div>

                                            <!-- Видалити папку -->
                                            <button
                                                class="dropdown-item"
                                                @click="dialogRemoveShow = true;removeItem = item;"
                                            >
                                                <i class="ri-delete-bin-fill align-bottom me-2 text-muted"></i>
                                                {{ $t('Delete') }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    
                    <!-- Опис -->
                    <div class="py-3" v-if="item.description != null">
                        <div class="row gy-3">
                            <div class="col-12">
                                <div>
                                    <p class="mb-1 fw-semibold">{{ $t('desc') }}</p>
                                    <p class="text-muted mb-1">{{ item.description }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </b-row>

    <!-- Модальне вікно створення/редагування папки -->
    <newFolder 
        v-if="showNewModal"
        @close="closeCreate();"
        :obj="this.form"
        :perm="this.permToEdit"
    />

    <!-- Модальне вікно підтвердження видалення папки -->
    <modalremove 
        v-if="dialogRemoveShow" 
        @close="dialogRemoveShow = false" 
        @remove="deleteFolder()" 
    />

    <!-- Вікно з імпортування холодних дзвінків -->
    <importForm 
        v-if="showImportForm === true" 
        @close="this.showImportForm = false" 
        @getdata="this.getdata()"
        :objFolders="this.rows"
    ></importForm>

    
</template>

<script>
import PageHeader from "@/components/page-header"; // хедер розділу
import newFolder from "./new" // картка створення папки
import modalremove from '@/components/modal/modalremove' // модальне вікно підтвердження видалення
import importForm from '../import' // компонент імпорту заявок
import { MoreHorizontalIcon } from "@zhuowenli/vue-feather-icons";
import { coldCallFolders } from "@/API" // клас для взаємодії з API 
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new coldCallFolders();

export default{
    components: {
        PageHeader,
        MoreHorizontalIcon,
        newFolder,
        modalremove,
        importForm
    },
    data(){
        return{
            title: 'ColdCalls', // Заголовок розділу
            showNewModal: false, // Прапорець на відображення вікна створення/редагування папки
            dialogRemoveShow: false, // Прапорець на відображення вікна видалення
            showImportForm: false, // Прапорець на відображення вікна імпорту заявок
            removeItem: '', // Дані папки для видалення
            objPages: '', // Об'єкт з даними про сторінки для розподілу даних по сторінках
            limit: '10', // Ліміт даних на сторінку
            limits: [ // Ліміти
                { value: '5'},
                { value: '10'},
                { value: '25'},
                { value: '50'},
                { value: '75'},
                { value: '100'},
            ],
            objParams: { // Параметри для фільтрації даних
                page: '1',
                pagelimit: '100',
                search: ''
            },
            rows: [], // Дані папок
            permToEdit: false, // Прапорець для редагування
            form: '', // Дані папки для редагування
        }
    },
    created(){
        this.getdata();
    },
    methods: {
        getdata(){
            /*
                Функція для отримання папок холодних дзвінків
                Викликає API для отримання папок з параметрами з об'єкта objParams.
            */

            apiServe.getFolders(this.objParams).then(result => {
                if(result.status == 'done'){
                    this.rows = result.data.items;
                    this.objPages = result.data
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var errorText = 'Помилка отримання папок холодних дзвінків'; 
                    var statusRequest = result.status;
                    var api = 'getFolders';
                    var fileRequest = 'src/views/calls/cold/folders/index.vue';
                    var params = this.objParams;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        deleteFolder(){
            /*
                Функція для видалення папки холодних дзвінків
                Викликає API для видалення папки з параметром ідентифікатору.
            */

            apiServe.deleteFolder(this.removeItem.callFolderId).then(result => {
                if(result.status == 'done'){
                    this.$toast.success(this.$t('Removed'))
                    this.dialogRemoveShow = false;
                    this.getdata();
                } else {
                    this.$toast.error(this.$t('error') + " " + result.error[0]);

                    var errorText = 'Помилка видалення папки холодних дзвінків'; 
                    var statusRequest = result.status;
                    var api = 'deleteFolder';
                    var fileRequest = 'src/views/calls/cold/folders/index.vue';
                    var params = this.removeItem.callFolderId;
                    var response = result

                    // Викликаємо функцію для відправки повідомлення в телеграм
                    sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
                    .then(response => console.log('Telegram API response:', response.data))
                    .catch(error => console.error('Telegram API error:', error));
                }
            })
        },
        editModal(e){
            // Вмикаємо режим редагування папки
            this.permToEdit = true;
            this.form = e;
            this.showNewModal = true
        },
        closeCreate(){
            // Закриваємо вікно
            this.permToEdit = false;
            this.showNewModal = false
            this.getdata();
        },
        searchB(search) {
            // Пошук
            this.objParams.search = search
            this.getdata()
        },
        changelimit: function(limit){
            /*
                Функція для зміни ліміту сторінки та виклику функції отримання даних getdata з новим лімітом
            */
            
            this.objParams.pagelimit = limit;
            this.getdata();
        },
    }
}
</script>